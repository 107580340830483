import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'
import { Button, Modal } from "react-bootstrap";

const ListTags = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [listTags, setTags] = useState([]);
  const [show, setShow] = useState(0)

  const columns = [
    {
      name: 'ID',
      selector: row => row.TagId,
      sortable: true,

    },
    {
      name: 'Tag Name',
      selector: row => row.Name,
      sortable: true,
    },
    {
      name: 'Published',
      selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
    },
    {
      name: 'Options',
      selector: row => <div>
        {permissions.PublishTag === true ? (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishTag(row.TagId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}
        {permissions.ManageTag === true ? (<NavLink to={"/Tags/" + row.TagId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>) : null}
        {permissions.DeleteTag && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.TagId)}><BiTrash /></button>)}
        <Modal
          show={row.TagId === show}
          onHide={() => setShow(0)}
        >
          <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <p>
                  Are you sure you want to
                  <b> Delete</b> the tag named <b>{row.Name}</b>?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2">
                  <Button
                    variant="primary"
                    className="form-control"
                    onClick={() => DeleteTag(row.TagId)}
                  >
                    Yes
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    variant="secondary"
                    className="form-control"
                    onClick={() => setShow(0)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

      </div>,
    }
  ];

  const DeleteTag = async (TagId) => {
    let response = await fetch(
      Variables.API_URL + "Tags/DeleteTag/" + TagId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetTags();
    } else {
      toast.error("Please Check With Administrator");
    }
  };



  async function TooglePublishTag(id) {
    let response = await fetch(
      Variables.API_URL + "Tags/TooglePublishTag/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetTags();
    } else {
      toast.error(result);
    }
  }

  async function GetTags() {
    const URL = Variables.API_URL + "Tags/GetTags";
    const response = await fetch(URL)
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();

    setTags(listItems);
  }
  //#region Use Effect 
  useEffect(() => {
    GetTags();
  }, [])
  //#endregion Use Effect 
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Tags</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Tags</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="mb-3 text-right">
                  {permissions.ManageTag === true ? (<NavLink to='/Tags/0' className='btn btn-primary'>Add Tag</NavLink>) : null}
                </div>
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={listTags}
                    pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick />
      </div>
    </div>
  )
}
export default ListTags;