import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import ReactTooltip from "react-tooltip";
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  AiOutlineClose, AiOutlineCheck,
  AiOutlineRight,
} from 'react-icons/ai';
import { Button, Modal } from "react-bootstrap";


const AllPages = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [listPages, setListPages] = useState([]);
  const [show, setShow] = useState(0)
  const columns = [
    {
      name: 'ID',
      selector: row => row.PageId
    },
    {
      name: 'Page Title',
      selector: row => row.Title,
      sortable: true,
      reorder: true,
    },
    {
      name: 'Published',
      selector: row => row.Publish ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
      reorder: true,
    },
    {
      name: 'Options',
      selector: row => <div>
        {permissions.PublishPage === true ? (<button className="btn btn-outline-warning btn-sm" onClick={() => TooglePublishPage(row.PageId)}>{row.Publish ? <BiHide data-tip="Unpublish" /> : <BiShowAlt data-tip="Publish" />}  </button>) : null}
        <ReactTooltip place="bottom" className="tooltip" effect="solid" />
        {permissions.ManagePage === true ? (<NavLink to={"/Pages/" + row.PageId} className="btn btn-outline-secondary btn-sm ms-2" ><BiEdit data-tip={"Manage " + row.Title} /></NavLink>) : null}
        <ReactTooltip place="bottom" className="tooltip" effect="solid" />
        {permissions.DeletePage && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.PageId)}> <RiDeleteBinLine data-tip={"Delete " + row.Title} /></button>)}
        <Modal
          show={row.PageId === show}
          onHide={() => setShow(0)}
        >
          <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <p>
                  Are you sure you want to
                  <b> Delete</b> the page named <b>{row.Title}</b>?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2">
                  <Button
                    variant="primary"
                    className="form-control"
                    onClick={() => DeletePage(row.PageId)}
                  >
                    Yes
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    variant="secondary"
                    className="form-control"
                    onClick={() => setShow(0)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>,
    }
  ];

  const DeletePage = async (PageId) => {
    let response = await fetch(
      Variables.API_URL + "Pages/DeletePage/" + PageId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetPages();
    } else {
      toast.error("Please Check With Administrator");
    }
  };
  async function TooglePublishPage(PageId) {
    let response = await fetch(
      Variables.API_URL + "Pages/TooglePublishPage/" + PageId,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },

      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetPages();
    } else {
      toast.error(result);
    }
  }

  async function GetPages() {
    const URL = Variables.API_URL + "Pages/GetPages";
    const response = await fetch(URL)
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setListPages(listItems);
  }
  //#region Use Effect 
  useEffect(() => {
    GetPages();
  }, [])
  //#endregion Use Effect 
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Pages</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List Pages</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>List Pages</h3>
                  </div>
                  <div className="col-lg-4 text-right">
                    {permissions.ManagePage === true ? (<NavLink to='/Pages/0' className='btn btn-primary'>Add Page</NavLink>) : null}
                  </div>
                </div>
              </div> */}
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-12 text-end">
                    {permissions.ManagePage === true ? (<NavLink to='/Pages/0' className='btn btn-primary'>Add Page</NavLink>) : null}
                  </div>
                </div>
                <div className="portalTable">
                  <DataTable
                    columns={columns}
                    data={listPages}
                    pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick />
      </div>
    </div>
  )
}
export default AllPages;