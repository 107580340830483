import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tab, Row, Col, Nav, Button, Collapse } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import ProductBrands from "./Components/ProductBrands";
import ProductGeneral from "./Components/ProductGeneral";
import ProductCategories from "./Components/ProductCategories";
import useCollapse from "react-collapsed";
import ProductGalery from "./Components/ProductGalery";
import VariationGallery from "./Components/VariationGallery";
import ProductDetails from "./Components/ProductDetails";
import ProductInventory from "./Components/ProductInventory";
import Shipping from "./Components/Shipping";
import LinkedProducts from "./Components/LinkedProducts";
import ProductAttributes from "./Components/ProductAttributes";
import ProductVariation from "./Components/ProductVariation";
import ProductImage from "./Components/ProductImage";
import ProductMaterials from "./Components/ProductMaterials";
import Advanced from "./Components/Advanced";
import moment from "moment";
import { AiOutlineRight } from "react-icons/ai";
function ManageProducts() {
  let { ProductID } = useParams();

  const multiselectRef = React.createRef();
  let navigate = useNavigate();
  const [tabkey, setTabKey] = useState("General");
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const [collapsOpen, setCollapsOpen] = useState(-1);
  // handle tabKeyEvent
  function handleSelectKey(e) {
    setTabKey(e);
  }
  function handleProductDataType(e) {
    if (e.target.value == 1) {
      setTabKey("General");
    } else {
      setTabKey("Inventory");
    }
    setProductType(e.target.value);
  }

  //Brands
  const [brands, setBrands] = useState([]);
  const [assignedBrands, setAssignedBrands] = useState([]);

  //Categories
  const [categories, setCategories] = useState([]);
  const [assignedParentCategories, setAssignedParentCategories] = useState([]);
  const [assignedSubCategories, setAssignedSubCategories] = useState([]);
  const [assignedCategories, setAssignedCategories] = useState([]);

  //General
  const [regularPrices, setRegularPrices] = useState(0);
  const [salesPrices, setSalesPrices] = useState(0);

  const [fromDate, setFromDate] = useState(new Date("1800-01-01T00:00:00"));
  const [toDate, setToDate] = useState(new Date("1800-01-01T00:00:00"));
  const [isSchedualeSale, setIsSchedualeSale] = useState(false);

  //#region ///////////////////////  Products

  const [product, setProduct] = useState([]);

  const [productTypeValue, setProductTypeValue] = useState(1);
  const [productType, setProductType] = useState(1); // Returns false in case of a single product and true in case of a varihandleSubmitable
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [smallDescription, setSmallDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [keyWords, setKeyWords] = useState("");
  const [metaDetails, setMetaDetails] = useState("");
  const [isPublished, setIsPublished] = useState(true);
  const [activeBar, toggleSideBarItem] = useState(1);
  const [soldIndividually, setSoldIndividually] = useState(false);
  const [warrantyAndShipping, setWarrantyAndShipping] = useState("");
  const [washAndCare, setWashAndCare] = useState("");
  const [deliveryAndReturns, setDeliveryAndReturns] = useState("");
  const [piece, setPiece] = useState(0);
  //Inventory
  const [sku, setSKU] = useState("");
  const [manageStock, setManageStock] = useState(false);
  const [stockStatus, setStockStatus] = useState(1);
  const [backorders, setBackorders] = useState(1);
  const [stockQuantity, setStockQuantity] = useState(0);
  const [threshold, setThreshold] = useState(0);
  const [newIn, setNewIn] = useState(false);
  const [newInStartDate, setNewInStartDate] = useState(
    new Date("1800-01-01T00:00:00")
  );
  const [newInEndDate, setNewInEndDate] = useState(
    new Date("1800-01-01T00:00:00")
  );
  const [scheduleNewIn, setScheduleNewIn] = useState(false);
  //Shipping
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // get product details
  async function GetProductDetails(ProductID) {
    const URL = Variables.API_URL + "Products/GetProduct/" + ProductID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setProduct(listItems);
    setProductName(listItems.ProductName);
    setProductDescription(listItems.ProductDescription);
    setSKU(listItems.Sku);
    setManageStock(listItems.IsStockManaged);
    setStockStatus(listItems.StockStatusId);
    setSoldIndividually(listItems.IsIndividuallySold);
    setStockQuantity(listItems.Quantity);
    setThreshold(listItems.Threshold);
    setWeight(listItems.Weight);
    setWidth(listItems.Width);
    setLength(listItems.Length);
    setHeight(listItems.Height);
    setBackorders(listItems.Backorders);
    setProductType(listItems.ProductTypeId);
    setIsPublished(listItems.IsPublished);
    setNewIn(listItems.NewIn);
    if (listItems.NewInEndDate !== null && listItems.NewInStartDate !== null) {
      setScheduleNewIn(true);
    }
    setNewInEndDate(listItems.NewInEndDate);
    setNewInStartDate(listItems.NewInStartDate);

    if (listItems.ProductTypeId == 1) {
      setTabKey("General");
    } else {
      setTabKey("Inventory");
    }
    setDdlSelectedAttribute(listItems.ProductTypeId);
    setRegularPrices(listItems.RegularPrice);
    setSalesPrices(listItems.SalesPrice);
    setSmallDescription(listItems.SmallDescription);

    setMetaDetails(listItems.MetaDetails);
    setMetaTitle(listItems.MetaTitle);
    setKeyWords(listItems.MetaKeyWords);
    setProductImage(listItems.ProductImage);
    setShowingProductImage(listItems.Image);
    setWarrantyAndShipping(listItems.WarrantyAndShipping);
    setWashAndCare(listItems.WashAndCare);
    setDeliveryAndReturns(listItems.DeliveryAndReturns);
    setPiece(listItems.Piece);
  }
  //handle keywords
  function handleKeyWords(e) {
    if (e != "") {
      setKeyWords(e.target.value.replace(/ /g, ","));
    }
  }

  //Toggle Publish
  async function TogglePublish() {
    let response = await fetch(
      Variables.API_URL + "Products/TooglePublishProduct/" + ProductID,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      GetProductDetails(ProductID);
    } else {
      toast.error(result);
    }
  }

  //#endregion

  //#region ///////////////////////  Tags

  //Tags
  const [tags, setTags] = useState([]);
  const [mappedTags, setMappedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  //Get All Tags
  const GetTags = () => {
    const URL = Variables.API_URL + "Tags/GetTags";
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        setTags(result);
      });
    GetAssignedTags();
  };

  //Get Assigned Tags
  const GetAssignedTags = () => {
    const URL =
      Variables.API_URL + "AssignTagToProduct/GetAssignedTags/" + ProductID;
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        setSelectedTags(result);
      });
  };

  // Handle Select tags
  function handleselectTag(e) {
    setSelectedTags(e);
  }

  //Handle remove Tags
  function handleremovetag(e) {
    setSelectedTags(e);
  }

  //Handle select tags
  function handleSearchTag(e) {
    setMappedTags(
      tags.filter((item) => item.Name.toLowerCase().includes(e.toLowerCase()))
    );
  }

  //#endregion

  //#region ///////////////////////  Attributes
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [productAttributesValues, setProductAttributesValues] = useState([]);
  const [ProductVariationAttributes, setAssignedAttributes] = useState([]);
  const [savedAssigned, setSavedAssigned] = useState([]);
  const [ddlSelectedAttribute, setDdlSelectedAttribute] = useState(0);
  const [productSelectedAttributes, setProductSelectedAttributes] = useState(
    []
  );
  const [attributesList, setAttributesList] = useState([]);
  //#endregion

  //#region ///////////////////////  Linked Products

  const [selectedUpSells, setSelectedUpSells] = useState([]);
  const [selectedCrossSells, setSelectedCrossSells] = useState([]);
  const [mappedUpSells, setMappedUpSells] = useState([]);
  const [mappedCrossSells, setMappedCrossSells] = useState([]);

  //#endregion

  //#region ///////////////////////  Variation

  const [variationType, setVariationType] = useState(1);
  const [variations, setVariations] = useState([]);
  const [savedVariations, setSavedVariations] = useState([]);
  const [variationScheduleStatus, setVariationScheduleStatus] = useState(-1);
  const [variationAttributes, setVariationAttributes] = useState([]);
  //#endregion

  //#region ////////////////////// gallery
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [showingMedia, setShowingMedia] = useState([]);
  const [productImage, setProductImage] = useState(0);
  const [showingProductImage, setShowingProductImage] = useState("");
  function fetchAssignedMedia() {
    fetch(
      Variables.API_URL + "AssignMediaToProduct/GetAssignedMedia/" + ProductID,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => Response.json())
      .then((Result) => {
        setShowingMedia(Result);
        setSelectedMedia(Result);
      });
  }

  //#endregion

  //#region ///////////////////////// Materials

  const [assignedMaterials, setAssignedMaterials] = useState([]);
  const [filteredAssignedMaterials, setFilteredAssignedMaterials] = useState(
    []
  );
  const [unassignedMaterials, setUnassignedMaterials] = useState([]);
  const [filteredUnassignedMaterials, setFilteredUnassignedMaterials] =
    useState([]);
  //#endregion

  function isValid() {
    debugger;
    if (productName === "") {
      toast.error("Product Name is required");
      return false;
    }
    if (sku === "") {
      toast.error("Sku is required");

      return false;
    }
    if (piece == 0 || isNaN(piece)) {
      var element = document.getElementById("piece");
      element.classList.add("is-invalid");
      element.focus();
      toast.error("Number of pieces is required");
      return false;
    }
    if (piece < 0) {
      var element = document.getElementById("piece");
      element.classList.add("is-invalid");
      element.focus();
      toast.error("Number of pieces cannot be a negative value");
      return false;
    }
    var count = 0;
    assignedMaterials.forEach((element) => {
      count += element.Percentage;
    });

    if (ValidateVariationAttribute()) {
      toast.error("Please choose all Attributes");
      return false;
    }
    if (ValidateVariationDuplicateAttributes()) {
      toast.error(
        "You CAN'T have two variations with the same Attribute Values "
      );
      return false;
    }

    if (count > 100) {
      toast.error("You cannot choose more than 100 % for the materials");
      return false;
    }
    if (productType == 1 && productImage == 0) {
      toast.error("Please choose an image");
      return false;
    }
    var defaultVariation;
    savedVariations.forEach((element) => {
      if (element.IsDefaultVariation === true) {
        defaultVariation = element;
      }
    });
    if (defaultVariation === undefined && productType == 2) {
      toast.error("Please choose a default variation");
      return false;
    }

    return true;
  }

  //Check if all attributes are selected
  const ValidateVariationAttribute = () => {
    debugger;
    var attribute = false;
    if (savedVariations.length > 0) {
      savedVariations.forEach((item) => {
        if (item.ProductVariationAttributes.length < savedAssigned.length) {
          attribute = true;
        } else {
          item.ProductVariationAttributes.forEach((element) => {
            if (element.AttributeValueId == 0) {
              attribute = true;
            }
          });
        }
      });
    }
    return attribute;
  };
  //Check variations attribute values are duplicated
  const ValidateVariationDuplicateAttributes = () => {
    debugger;
    var sortedList = [];
    savedVariations.forEach((element, i) => {
      sortedList.push(
        element.ProductVariationAttributes.sort((a, b) => a.AttributeId - b.AttributeId)
      );
    });

    var isExist = false;
    sortedList.map((element, i) => {
      sortedList.map((subElement, index) => {
        if (i !== index) {
          if (JSON.stringify(element) == JSON.stringify(subElement)) {
            isExist = true;
          }
        }
      });
    });
    return isExist;
  };

  const HandleSubmit = async (e) => {
    if (isValid()) {
      //debugger;
      e.preventDefault();
      var publish = true;
      var categoriesList = [];
      for (var i = 0; i < assignedParentCategories.length; i++) {
        categoriesList.push(assignedParentCategories[i]);
      }
      for (var i = 0; i < assignedSubCategories.length; i++) {
        categoriesList.push(assignedSubCategories[i]);
      }
      var attributes = [];
      for (var i = 0; i < savedAssigned.length; i++) {
        for (var j = 0; j < savedAssigned[i].AttributeValues.length; j++) {
          attributes.push(savedAssigned[i].AttributeValues[j]);
        }
      }

      if (
        assignedParentCategories.length == 0 &&
        assignedSubCategories.length == 0
      ) {
        publish = false;
      }
      // // case of insert
      if (parseInt(ProductID) === 0) {
        let response = await fetch(Variables.API_URL + "Products/AddProduct", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": Variables.API_URL,
          },
          body: JSON.stringify({
            ProductName: productName,
            ProductDescription: productDescription,
            ProductImage: productImage,
            RegularPrice: regularPrices,
            SalesPrice: salesPrices,
            IsSalesScheduled: isSchedualeSale,
            SalesFrom: fromDate,
            SalesTo: toDate,
            Sku: sku,
            IsStockManaged: manageStock,
            StockStatusId: stockStatus,
            IsIndividuallySold: soldIndividually,
            ProductTypeId: productType,
            Weight: weight,
            Length: length,
            Width: width,
            Height: height,
            Quantity: stockQuantity,
            Backorders: backorders,
            Threshold: threshold,
            MetaTitle: metaTitle,
            MetaKeyWords: keyWords,
            MetaDetails: metaDetails,
            SmallDescription: smallDescription,
            IsPublished: publish,
            NewIn: newIn,
            NewInStartDate: newInStartDate,
            NewInEndDate: newInEndDate,
            WarrantyAndShipping: warrantyAndShipping,
            WashAndCare: washAndCare,
            DeliveryAndReturns: deliveryAndReturns,
            Piece: piece,
            Categories: categoriesList.map((item) => ({
              CategoryId: item.CategoryId,
            })),
            Brands: assignedBrands.map((item) => ({
              BrandId: item.BrandId,
            })),
            Media: selectedMedia.map((item) => ({
              MediaId: item.MediaId,
            })),
            Tags: selectedTags.map((item) => ({
              TagId: item.TagId,
            })),
            ProductAttributes: attributes.map((item) => ({
              AttributeValueId: item.AttributeValueId,
              AttributeId: item.AttributeId,
            })),
            CrossSells: selectedCrossSells.map((item) => ({
              CrossSellId: item.ProductId,
            })),
            UpSells: selectedUpSells.map((item) => ({
              UpsellId: item.ProductId,
            })),
            Materials: assignedMaterials.map((item) => ({
              MaterialId: item.MaterialId,
              Percentage: item.Percentage,
            })),
            Variations: savedVariations,
          }),
        });

        let result = await response.json();
        if (response.status == 200) {
          toast.success(result);
          navigate("/Products");
        } else {
          toast.error(result);
        }
      }
      // case of update
      else {
        let response = await fetch(
          Variables.API_URL + "Products/UpdateProduct/" + ProductID,
          {
            method: "Put",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": Variables.API_URL,
            },
            body: JSON.stringify({
              ProductName: productName,
              ProductDescription: productDescription,
              ProductImage: productImage,
              RegularPrice: regularPrices,
              SalesPrice: salesPrices,
              IsSalesScheduled: isSchedualeSale,
              SalesFrom: fromDate,
              SalesTo: toDate,
              Sku: sku,
              IsStockManaged: manageStock,
              StockStatusId: stockStatus,
              IsIndividuallySold: soldIndividually,
              ProductTypeId: productType,
              Weight: weight,
              Length: length,
              Width: width,
              Height: height,
              Quantity: stockQuantity,
              Backorders: backorders,
              Threshold: threshold,
              MetaTitle: metaTitle,
              MetaKeyWords: keyWords,
              MetaDetails: metaDetails,
              SmallDescription: smallDescription,
              IsPublished: publish,
              NewIn: newIn,
              NewInStartDate: newInStartDate,
              NewInEndDate: newInEndDate,
              WarrantyAndShipping: warrantyAndShipping,
              WashAndCare: washAndCare,
              DeliveryAndReturns: deliveryAndReturns,
              Piece: piece,
              Categories: categoriesList.map((item) => ({
                CategoryId: item.CategoryId,
              })),
              Brands: assignedBrands.map((item) => ({
                BrandId: item.BrandId,
              })),
              Media: selectedMedia.map((item) => ({
                MediaId: item.MediaId,
              })),
              Tags: selectedTags.map((item) => ({
                TagId: item.TagId,
              })),
              ProductAttributes: attributes.map((item) => ({
                AttributeValueId: item.AttributeValueId,
                AttributeId: item.AttributeId,
              })),
              CrossSells: selectedCrossSells.map((item) => ({
                CrossSellId: item.ProductId,
              })),
              UpSells: selectedUpSells.map((item) => ({
                UpsellId: item.ProductId,
              })),
              Materials: assignedMaterials.map((item) => ({
                MaterialId: item.MaterialId,
                Percentage: item.Percentage,
              })),
              Variations: savedVariations,
            }),
          }
        );
        let result = await response.json();
        if (response.status == 200) {
          toast.success(result);
          navigate("/Products");
        } else {
          toast.error(result);
        }
      }
    }
  };

  useEffect(() => {
    GetTags(); // Get All Tags
    if (ProductID != 0) {
      setTimeout(() => {
        GetProductDetails(ProductID);
      }, 1000);
      fetchAssignedMedia();
    }
  }, [ProductID]);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{ProductID == "0" ? "Add" : "Update"} Product</h2>
            <ul className="portalPath">
              <li>
                Home <AiOutlineRight />
              </li>
              <li>
                <Link to="/Products">
                  Products <AiOutlineRight />
                </Link>
              </li>
              <li>Manage Product</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* <form> */}
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{productName}</h3>
                  </div>
                  <div className="col-lg-4 text-right">
                    {ProductID != 0 && (
                      <button
                        className={
                          isPublished
                            ? "btn btn-primary ms-2 "
                            : " btn btn-primary ms-2 "
                        }
                        onClick={() => TogglePublish()}
                      >
                        {isPublished ? "Unpublish" : "Publish"}
                      </button>
                    )}
                    <NavLink to="/Products" className="btn btn-secondary ms-2">
                      Cancel
                    </NavLink>
                    <button
                      type="submit"
                      className="btn btn-primary ms-2"
                      onClick={HandleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      <ProductDetails
                        productName={productName}
                        setProductName={setProductName}
                        productDescription={productDescription}
                        setProductDescription={setProductDescription}
                        smallDescription={smallDescription}
                        setSmallDescription={setSmallDescription}
                        variations={variations}
                        setVariations={setVariations}
                      />
                      <div className="col-lg-12">
                        <br />
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-2"> Product Data -</div>

                              <div className="col-4">
                                <select
                                  className="form-select"
                                  value={productType}
                                  onChange={(e) => {
                                    handleProductDataType(e);
                                    toggleSideBarItem(e.target.value);
                                  }}
                                >
                                  <option value={1} key="1">
                                    Simple Product
                                  </option>
                                  <option value={2} key="2">
                                    Variable Product
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="card-body productCard">
                            <br />
                            <div className="row">
                              <div className="col-lg-12">
                                <Tab.Container
                                  id="left-tabs-example"
                                  activeKey={tabkey}
                                  onSelect={(e) => handleSelectKey(e)}
                                >
                                  <Row>
                                    {/* Options */}
                                    <Col sm={2}>
                                      <Nav
                                        variant="pills"
                                        className="flex-column"
                                      >
                                        {productType == 1 && (
                                          <Nav.Item>
                                            <Nav.Link eventKey="General">
                                              General
                                            </Nav.Link>
                                          </Nav.Item>
                                        )}

                                        <Nav.Item>
                                          <Nav.Link eventKey="Inventory">
                                            Inventory
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Shipping">
                                            Shipping{" "}
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="LinkedProducts">
                                            {" "}
                                            Linked Products
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Attributes">
                                            {" "}
                                            Attributes
                                          </Nav.Link>
                                        </Nav.Item>
                                        {productType == 2 ? (
                                          <Nav.Item>
                                            <Nav.Link eventKey="Variations">
                                              {" "}
                                              Variations
                                            </Nav.Link>
                                          </Nav.Item>
                                        ) : null}
                                        <Nav.Item>
                                          <Nav.Link eventKey="Advanced">
                                            {" "}
                                            Advanced
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Seo">
                                            {" "}
                                            SEO
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Materials">
                                            {" "}
                                            Materials
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    {/* Right Tabs */}
                                    <Col
                                      sm={10}
                                      className="d-flex justify-content-center flex-column"
                                    >
                                      <Tab.Content>
                                        {/* General */}
                                        {productType == 1 && (
                                          <Tab.Pane eventKey="General">
                                            <ProductGeneral
                                              productDetail={product}
                                              regularPrices={regularPrices}
                                              setRegularPrices={
                                                setRegularPrices
                                              }
                                              salesPrices={salesPrices}
                                              setSalesPrices={setSalesPrices}
                                              fromDate={fromDate}
                                              setFromDate={setFromDate}
                                              toDate={toDate}
                                              setToDate={setToDate}
                                              isSchedualeSale={isSchedualeSale}
                                              setIsSchedualeSale={
                                                setIsSchedualeSale
                                              }
                                            />
                                          </Tab.Pane>
                                        )}
                                        {/* Inventory */}
                                        <Tab.Pane
                                          eventKey="Inventory"
                                          id="InventoryTab"
                                        >
                                          <ProductInventory
                                            sku={sku}
                                            setSKU={setSKU}
                                            manageStock={manageStock}
                                            setManageStock={setManageStock}
                                            stockStatus={stockStatus}
                                            setStockStatus={setStockStatus}
                                            stockQuantity={stockQuantity}
                                            setStockQuantity={setStockQuantity}
                                            backorders={backorders}
                                            setBackorders={setBackorders}
                                            threshold={threshold}
                                            setThreshold={setThreshold}
                                            soldIndividually={soldIndividually}
                                            setSoldIndividually={
                                              setSoldIndividually
                                            }
                                            newIn={newIn}
                                            setNewIn={setNewIn}
                                            setNewInStartDate={
                                              setNewInStartDate
                                            }
                                            setNewInEndDate={setNewInEndDate}
                                            newInStartDate={newInStartDate}
                                            newInEndDate={newInEndDate}
                                            scheduleNewIn={scheduleNewIn}
                                            setScheduleNewIn={setScheduleNewIn}
                                            piece={piece}
                                            setPiece={setPiece}
                                          />
                                        </Tab.Pane>
                                        {/* Shipping */}
                                        <Tab.Pane eventKey="Shipping">
                                          <Shipping
                                            weight={weight}
                                            setWeight={setWeight}
                                            length={length}
                                            setLength={setLength}
                                            width={width}
                                            setWidth={setWidth}
                                            height={height}
                                            setHeight={setHeight}
                                          />
                                        </Tab.Pane>
                                        {/* Linked Products */}
                                        <Tab.Pane eventKey="LinkedProducts">
                                          <LinkedProducts
                                            ProductID={ProductID}
                                            mappedUpSells={mappedUpSells}
                                            setMappedUpSells={setMappedUpSells}
                                            mappedCrossSells={mappedCrossSells}
                                            setMappedCrossSells={
                                              setMappedCrossSells
                                            }
                                            selectedUpSells={selectedUpSells}
                                            setSelectedUpSells={
                                              setSelectedUpSells
                                            }
                                            selectedCrossSells={
                                              selectedCrossSells
                                            }
                                            setSelectedCrossSells={
                                              setSelectedCrossSells
                                            }
                                          />
                                        </Tab.Pane>
                                        {/* Attributes */}
                                        <Tab.Pane eventKey="Attributes">
                                          <ProductAttributes
                                            savedAssigned={savedAssigned}
                                            setSavedAssigned={setSavedAssigned}
                                            productSelectedAttributes={
                                              productSelectedAttributes
                                            }
                                            setProductSelectedAttributes={
                                              setProductSelectedAttributes
                                            }
                                            setAssignedAttributes={
                                              setAssignedAttributes
                                            }
                                            ddlSelectedAttribute={
                                              ddlSelectedAttribute
                                            }
                                            setDdlSelectedAttribute={
                                              setDdlSelectedAttribute
                                            }
                                            attributesList={attributesList}
                                            setAttributesList={
                                              setAttributesList
                                            }
                                            selectedAttributes={
                                              selectedAttributes
                                            }
                                            setSelectedAttributes={
                                              setSelectedAttributes
                                            }
                                            setProductAttributesValues={
                                              setProductAttributesValues
                                            }
                                            ProductID={ProductID}
                                          />
                                        </Tab.Pane>
                                        {/* Advanced */}
                                        <Tab.Pane eventKey="Advanced">
                                          <div className="container">
                                            <Advanced
                                              warrantyAndShipping={
                                                warrantyAndShipping
                                              }
                                              washAndCare={washAndCare}
                                              setWarrantyAndShipping={
                                                setWarrantyAndShipping
                                              }
                                              setWashAndCare={setWashAndCare}
                                              deliveryAndReturns={
                                                deliveryAndReturns
                                              }
                                              setDeliveryAndReturns={
                                                setDeliveryAndReturns
                                              }
                                            />
                                          </div>
                                        </Tab.Pane>
                                        {/* Variations */}
                                        {productType == 2 && (
                                          <Tab.Pane eventKey="Variations">
                                            <ProductVariation
                                              savedAssigned={savedAssigned}
                                              setSavedVariations={
                                                setSavedVariations
                                              }
                                              setSavedAssigned={
                                                setSavedAssigned
                                              }
                                              variationType={variationType}
                                              setVariationType={
                                                setVariationType
                                              }
                                              variations={variations}
                                              setVariations={setVariations}
                                              variationScheduleStatus={
                                                variationScheduleStatus
                                              }
                                              setVariationScheduleStatus={
                                                setVariationScheduleStatus
                                              }
                                              collapsOpen={collapsOpen}
                                              setCollapsOpen={setCollapsOpen}
                                              variationAttributes={
                                                variationAttributes
                                              }
                                              setVariationAttributes={
                                                setVariationAttributes
                                              }
                                              ProductID={ProductID}
                                              Sku={sku}
                                              productDescription={
                                                productDescription
                                              }
                                            />
                                          </Tab.Pane>
                                        )}
                                        <Tab.Pane eventKey="Seo">
                                          <div className="container">
                                            <div className="col-9 ">
                                              <div className="row mb-3">
                                                <div className="col-12">
                                                  <label className="mb-2">
                                                    Meta Title
                                                  </label>
                                                  <input
                                                    type="text"
                                                    value={metaTitle}
                                                    onChange={(e) =>
                                                      setMetaTitle(
                                                        e.target.value
                                                      )
                                                    }
                                                    className="form-control"
                                                  />
                                                </div>
                                              </div>
                                              <div className="row mb-3">
                                                <div className="col-12">
                                                  <label className="mb-2">
                                                    Meta Key Words
                                                  </label>
                                                  <input
                                                    type="text"
                                                    value={keyWords}
                                                    onChange={handleKeyWords}
                                                    className="form-control"
                                                  />
                                                </div>
                                              </div>
                                              <div className="row mb-3">
                                                <div className="col-12">
                                                  <label className="mb-2">
                                                    Meta Details
                                                  </label>
                                                  <CKEditor
                                                    editor={ClassicEditor}
                                                    data={metaDetails}
                                                    value={metaDetails}
                                                    onChange={(
                                                      event,
                                                      editor
                                                    ) => {
                                                      const data =
                                                        editor.getData();
                                                      setMetaDetails(data);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Materials">
                                          <div className="container">
                                            <br />
                                            <ProductMaterials
                                              ProductID={ProductID}
                                              assignedMaterials={
                                                assignedMaterials
                                              }
                                              setAssignedMaterials={
                                                setAssignedMaterials
                                              }
                                              filteredAssignedMaterials={
                                                filteredAssignedMaterials
                                              }
                                              setFilteredAssignedMaterials={
                                                setFilteredAssignedMaterials
                                              }
                                              unassignedMaterials={
                                                unassignedMaterials
                                              }
                                              setUnassignedMaterials={
                                                setUnassignedMaterials
                                              }
                                              filteredUnassignedMaterials={
                                                filteredUnassignedMaterials
                                              }
                                              setFilteredUnassignedMaterials={
                                                setFilteredUnassignedMaterials
                                              }
                                            />
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Side Bar Right */}
                  <div className="col-4">
                    <div className="card ">
                      <div className="card-header">
                        <b> Product Brands</b>
                      </div>
                      {/* Brands */}
                      <ProductBrands
                        productID={ProductID}
                        brands={brands}
                        setBrands={setBrands}
                        assignedBrands={assignedBrands}
                        setAssignedBrands={setAssignedBrands}
                      />
                    </div>
                    <br></br>
                    {/* Products */}
                    <div className="card">
                      <div className="card-header">
                        <b> Product Categories</b>
                      </div>
                      <div className="card-body productCategories">
                        <ProductCategories
                          productID={ProductID}
                          categories={categories}
                          setCategories={setCategories}
                          assignedParentCategories={assignedParentCategories}
                          setAssignedParentCategories={
                            setAssignedParentCategories
                          }
                          assignedSubCategories={assignedSubCategories}
                          setAssignedSubCategories={setAssignedSubCategories}
                          assignedCategories={assignedCategories}
                          setAssignedCategories={setAssignedCategories}
                        />
                      </div>
                    </div>
                    <br />
                    {productType == 1 ? (
                      <>
                        <ProductImage
                          productImage={productImage}
                          setProductImage={setProductImage}
                          showingProductImage={showingProductImage}
                          setShowingProductImage={setShowingProductImage}
                        />

                        {productImage !== 0 ? (
                          <div
                            className="col-lg-6"
                            style={{ marginInlineStart: "20%" }}
                          >
                            <img
                              className="form-control"
                              src={
                                Variables.API_PUBLISHED + showingProductImage
                              }
                            />
                          </div>
                        ) : null}

                        <br></br>
                        <ProductGalery
                          setShowingMedia={setShowingMedia}
                          showingMedia={showingMedia}
                          setSelectedMedia={setSelectedMedia}
                          selectedMedia={selectedMedia}
                        />
                        <div className="row">
                          {showingMedia.map((m, i) => {
                            return (
                              <div className="col-3" key={i}>
                                <img
                                  src={Variables.API_PUBLISHED + m.Image}
                                  className="form-control"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                    <br></br>

                    <div className="card">
                      <div className="card-header">
                        <b> Product Tags</b>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-8">
                            <Multiselect
                              options={mappedTags}
                              selectedValues={selectedTags}
                              displayValue="Name"
                              onSelect={handleselectTag}
                              onSearch={handleSearchTag}
                              onRemove={handleremovetag}
                              placeholder="Tags"
                            />
                          </div>
                          <div className="col-lg-4 text-right">
                            <button
                              className="btn btn-primary"
                              onClick={() => navigate("/Tags/0")}
                            >
                              Add Tag
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
}

export default ManageProducts;
