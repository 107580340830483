import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom"
import { Variables } from "../../Variations/Variations"
import { BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai'

const AllWidgets = () => {
    const [widgets, setWidgets] = useState();
    const permissions = Util.decrypt(localStorage.getItem("Permissions"));
    const columns = [
        {
            name: 'ID',
            selector: row => row.WidgetId,
            sortable: true,

        },
        {
            name: 'Widget Name',
            selector: row => row.Title,
            sortable: true,
        },
        {
            name: 'Published',
            selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
            sortable: true,
        },

        {
            name: 'Options',
            selector: row => <div>
                {permissions.ManageWidget === true ? (<NavLink to={"/Widgets/" + row.WidgetId} className="btn btn-outline-secondary btn-sm" ><BiEdit /></NavLink>) : null}
                {permissions.PublishWidget === true ? (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => TooglePublishWidget(row.WidgetId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>) : null}

            </div>,
        }
    ];

    async function TooglePublishWidget(id) {
        let response = await fetch(
            Variables.API_URL + "Widgets/TooglePublishWidget/" + id,
            {
                method: "Put",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": Variables.API_URL,
                },
            }
        );
        let result = await response.json();
        if (response.ok) {
            toast.success(result);
            GetWidgets();
        } else {
            toast.error(result);
        }
    }

    async function GetWidgets() {
        const URL = Variables.API_URL + "Widgets/GetWidgets";
        const response = await fetch(URL)
        if (!response.ok) throw Error("Did Not Receive expected data");
        const listItems = await response.json();

        setWidgets(listItems);
    }
    //#region Use Effect 
    useEffect(() => {
        GetWidgets();
    }, [])
    //#endregion Use Effect 
    return (
        <div className="rightSide">
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-12">
                        <h2>List Widgets</h2>
                        <ul className="portalPath">
                            <li>Home <AiOutlineRight /></li>
                            <li>List Widgets</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {/* <div className="card-header">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>List Widgets</h3>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-body">
                                <div className="col-12 mb-3 text-end">
                                    {permissions.ManageWidget === true ? (<NavLink to='/Widgets/0' className='btn btn-primary'>Add Widget</NavLink>) : null}
                                </div>
                                <div className="portalTable">
                                    <DataTable
                                        columns={columns}
                                        data={widgets}
                                        pagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick />
            </div>
        </div>
    )
}
export default AllWidgets;