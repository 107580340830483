import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { BiTrash, BiEdit, BiShowAlt, BiHide } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from "react-icons/ai";
import { Button, Modal } from "react-bootstrap";

const AllMenus = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [menus, setMenus] = useState();
  const [show, setShow] = useState(0);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.MenuId,
      sortable: true,
    },
    {
      name: "Menu Name",
      selector: (row) => row.Title,
      sortable: true,
    },
    {
      name: "Published",
      selector: (row) =>
        row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
    },
    {
      name: "Options",
      selector: (row) => (
        <div>
          {permissions.PublishMenu === true ? (
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={() => TooglePublishMenu(row.MenuId)}
            >
              {row.IsPublished ? <BiHide /> : <BiShowAlt />}{" "}
            </button>
          ) : null}
          {permissions.ManageMenu === true ? (
            <NavLink
              to={"/Menus/" + row.MenuId}
              className="btn btn-outline-warning btn-sm ms-2"
            >
              <BiEdit />
            </NavLink>
          ) : null}
          {permissions.DeleteMenu && (
            <button
              className="btn btn-outline-danger btn-sm ms-2"
              onClick={() => DeleteMenu(row.MenuId)}
            >
              <BiTrash />
            </button>
          )}
          <Modal show={row.MenuId === show} onHide={() => setShow(0)}>
            <Modal.Header>
              <Modal.Title>Delete Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <p>
                    Are you sure you want to
                    <b> Delete</b> the menu named <b>{row.Title}</b>?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="container">
                <div className="row">
                  <div className="col-8"></div>
                  <div className="col-2">
                    <Button
                      variant="primary"
                      className="form-control"
                      onClick={() => DeleteMenu(row.MenuId)}
                    >
                      Yes
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button
                      variant="secondary"
                      className="form-control"
                      onClick={() => setShow(0)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ),
    },
  ];

  async function TooglePublishMenu(MenuId) {
    let response = await fetch(
      Variables.API_URL + "Menus/TooglePublishMenu/" + MenuId,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetMenus();
    } else {
      toast.error(result);
    }
  }
  const DeleteMenu = async (MenuId) => {
    let response = await fetch(
      Variables.API_URL + "Menus/DeleteMenu/" + MenuId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetMenus();
    } else {
      toast.error("Please Check With Administrator");
    }
  };
  const Reset = async () => {
    let response = await fetch(Variables.API_URL + "MegaMenuCache/Reset", {
      method: "Delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": Variables.API_URL,
      },
    });
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      GetMenus();
    } else {
      toast.error("Please Check With Administrator");
    }
  };

  async function GetMenus() {
    const URL = Variables.API_URL + "Menus/GetMenus";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setMenus(listItems);
  }
  //#region Use Effect
  useEffect(() => {
    GetMenus();
  }, []);
  //#endregion Use Effect
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List Menus</h2>
            <ul className="portalPath">
              <li>
                Home <AiOutlineRight />
              </li>
              <li>List Menus</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>List Menus</h3>
                  </div>
                </div>
              </div> */}
              <div className="card-body">
                <div className="text-end mb-3">
                  <button className="btn btn-primary " onClick={() => Reset()}>
                    {" "}
                    Clear Cache
                  </button>
                  {permissions.ManageMenu ? (
                    <NavLink to="/Menus/0" className="btn btn-primary">
                      Add Menu
                    </NavLink>
                  ) : null}
                </div>

                <div className="portalTable">
                  <DataTable columns={columns} data={menus} pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};
export default AllMenus;
