import React, { useState, useEffect } from "react";
import { useParams, NavLink, useNavigate, Link } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { Variables } from "../../Variations/Variations";
import { toast } from "react-toastify";
import { MdOutlineDelete, MdOutlineAssignmentReturned, MdManageSearch } from "react-icons/md";
import { AiOutlineRight } from "react-icons/ai";
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
const ManageGroup = () => {
  const [name, setName] = useState("");
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([])
  let { GroupID } = useParams();
  const navigate = useNavigate();
  const [assignedAttributeValues, setAssignedAttributeValues] = useState([]);
  const [filteredAssignedAttributeValues, setFilteredAssignedAttributeValues] =
    useState([]);
  const [unassignedAttributeValues, setUnassignedAttributeValues] = useState(
    []
  );
  const [
    filteredUnassignedAttributeValues,
    setFilteredUnassignedAttributeValues,
  ] = useState([]);
  const [isOpenUnAssignedAttributeValues, setisOpenUnAssignedAttributeValues] =
    useState(false);

  const [assignedAttributes, setAssignedAttributes] = useState([]);
  const [filteredAssignedAttributes, setFilteredAssignedAttributes] = useState(
    []
  );
  const [unassignedAttributes, setUnassignedAttributes] = useState([]);
  const [filteredUnassignedAttributes, setFilteredUnassignedAttributes] =
    useState([]);
  const [isOpenUnAssignedAttributes, setisOpenUnAssignedAttributes] =
    useState(false);

  const unassignedAttributeValuesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value  ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: " Attribute  ",
      selector: (row) => row.Attribute1,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button className="btn btn-outline-secondary btn-sm" onClick={() => AssignAttributeValue(row)}>
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedAttributeValuesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeValueId,
      sortable: true,
    },
    {
      name: " Attribute Value ",
      selector: (row) => row.AttributeValueName,
      sortable: true,
    },
    {
      name: " Attribute  ",
      selector: (row) => row.Attribute1,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm ms-2"
          onClick={() => UnAssignAttributeValue(row.AttributeValueId)}
        >
          <MdOutlineDelete />
        </button>
      ),
    },
  ];
  const unassignedAttributesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeId,
      sortable: true,
    },
    {
      name: " Attribute  ",
      selector: (row) => row.Attribute1,
      sortable: true,
    },

    {
      name: "Assign",
      selector: (row) => (
        <button className="btn btn-outline-secondary btn-sm" onClick={() => AssignAttribute(row)}>
          <MdOutlineAssignmentReturned />
        </button>
      ),
    },
  ];
  const assignedAttributesColumns = [
    {
      name: "ID",
      selector: (row) => row.AttributeId,
      sortable: true,
    },
    {
      name: " Attribute  ",
      selector: (row) => row.Attribute1,
      sortable: true,
    },

    {
      name: "UnAssign",
      selector: (row) => (
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => UnAssignAttribute(row.AttributeId)}
        >
          <MdOutlineDelete />
        </button>
      ),
    },
  ];

  async function AssignAttributeValue(row) {
    let response = await fetch(
      Variables.API_URL + "Group/AssignAttributeValueToGroup",
      {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeValueId: row.AttributeValueId,
          GroupId: GroupID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fecthUnAssignedAttributeValues();
      fetchAssignedAttributeValues();

    } else {
      toast.error(result);
    }
  }

  async function UnAssignAttributeValue(AttributeValueId) {
    let response = await fetch(
      Variables.API_URL + "Group/UnassignAttributeValueToGroup",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeValueId: AttributeValueId,
          GroupId: GroupID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedAttributeValues();
      fecthUnAssignedAttributeValues();
    } else {
      toast.error(result);
    }
  }
  const fecthUnAssignedAttributeValues = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "Group/GetUnAssignedAttributeValues/" + GroupID
    );
    result = await response.json();
    if (response.status === 200) {
      setUnassignedAttributeValues(result);
      setFilteredUnassignedAttributeValues(result);
    }
  };
  const fetchAssignedAttributeValues = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "Group/GetAssignedAttributeValues/" + GroupID
    );
    result = await response.json();
    setAssignedAttributeValues(result);
    setFilteredAssignedAttributeValues(result);
  };

  function handleSearchAssignedAttributeValues(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedAttributeValues.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setFilteredAssignedAttributeValues(result);
  }
  function handleSearchUnAssignedAttributeValues(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedAttributeValues.filter((data) => {
      return data.AttributeValueName.toLowerCase().search(value) != -1;
    });
    setFilteredUnassignedAttributeValues(result);
  }

  async function AssignAttribute(row) {
    let response = await fetch(
      Variables.API_URL + "Group/AssignAttributeToGroup",
      {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeId: row.AttributeId,
          GroupId: GroupID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fecthUnAssignedAttributes();
      fetchAssignedAttributes();

    } else {
      toast.error(result);
    }
  }

  async function UnAssignAttribute(AttributeId) {
    let response = await fetch(
      Variables.API_URL + "Group/UnassignAttributeToGroup",
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AttributeId: AttributeId,
          GroupId: GroupID,
        }),
      }
    );
    let result = await response.json();
    if (response.status === 200) {
      toast.success(result);
      fetchAssignedAttributes();
      fecthUnAssignedAttributes();
    } else {
      toast.error(result);
    }
  }
  const fecthUnAssignedAttributes = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "Group/GetUnAssignedAttributes/" + GroupID
    );
    result = await response.json();
    if (response.status === 200) {
      setUnassignedAttributes(result);
      setFilteredUnassignedAttributes(result);
    }
  };
  const fetchAssignedAttributes = async () => {
    let response, result;
    response = await fetch(
      Variables.API_URL + "Group/GetAssignedAttributes/" + GroupID
    );
    result = await response.json();
    setAssignedAttributes(result);
    setFilteredAssignedAttributes(result);
  };

  function handleSearchAssignedAttributes(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = assignedAttributes.filter((data) => {
      return data.AttributeName.toLowerCase(value).search() != -1;
    });
    setFilteredAssignedAttributes(result);
  }
  function handleSearchUnAssignedAttributes(e) {
    let value = e.target.value.toLowerCase();
    let result = [];
    result = unassignedAttributes.filter((data) => {
      return data.AttributeName.toLowerCase(value).search() != -1;
    });
    setFilteredUnassignedAttributes(result);
  }

  async function GetCategories() {
    const URL = Variables.API_URL + "Category/GetCategories";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllCategories(listItems);
    if (listItems.length != 0 && GroupID != 0) {
      GetAssignedCategories();
    }
  }
  async function GetBrands() {
    const URL = Variables.API_URL + "Brand/GetBrandsForDdl";
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setAllBrands(listItems);
    if (listItems.length != 0 && GroupID != 0) {
      GetAssignedBrands();
    }
  }
  async function GetAssignedBrands() {
    const URL = Variables.API_URL + "Group/GetAssignedBrands/" + GroupID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSelectedBrands(listItems);
  }
  async function GetAssignedCategories() {
    const URL = Variables.API_URL + "Group/GetAssignedCategories/" + GroupID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setSelectedCategories(listItems);
  }
  async function GetGroup() {
    const URL = Variables.API_URL + "Group/GetGroup/" + GroupID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setName(listItems.GroupName);
  }

  function IsValid() {
    if (name !== "") {
      return true;
    }

    return false;
  }
  function handleSubmit(e) {
    //debugger;
    if (IsValid()) {
      e.preventDefault();
      if (GroupID == 0) {
        const AddGroup = async () => {
          let response = await fetch(Variables.API_URL + "Group/AddGroup", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              GroupName: name,
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            toast.success("Added successfully");
            navigate("/Groups/" + result);
            fetch(
              Variables.API_URL + "Group/AssignCategoriesToGroup/" + result,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(
                  selectedCategories.map((item) => ({
                    GroupId: result,
                    CategoryId: item.CategoryId,
                  }))
                ),
              }
            );
            fetch(
              Variables.API_URL + "Group/AssignBrandsToGroup/" + result,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(
                  selectedBrands.map((item) => ({
                    GroupId: result,
                    BrandId: item.BrandId,
                  }))
                ),
              }
            );
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await AddGroup())();
        }, 100);
      } else {
        const UpdateGroup = async () => {
          let response = await fetch(
            Variables.API_URL + "Group/UpdateGroup/" + GroupID,
            {
              method: "Put",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                GroupName: name,
              }),
            }
          );
          let result = await response.json();
          if (response.status === 200) {
            toast.success("Updated successfully");
            navigate("/Groups");
            fetch(
              Variables.API_URL + "Group/AssignCategoriesToGroup/" + GroupID,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(
                  selectedCategories.map((item) => ({
                    GroupId: GroupID,
                    CategoryId: item.CategoryId,
                  }))
                ),
              }
            );
            fetch(
              Variables.API_URL + "Group/AssignBrandsToGroup/" + GroupID,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(
                  selectedBrands.map((item) => ({
                    BrandId: item.BrandId,
                  }))
                ),
              }
            );
          } else {
            toast.error(result);
          }
        };
        setTimeout(() => {
          (async () => await UpdateGroup())();
        }, 100);
      }
    } else {
      toast.error("Please fill all required fields!");
    }
  }

  useEffect(() => {
    if (GroupID != 0) {
      fecthUnAssignedAttributeValues();
      fetchAssignedAttributeValues();
      fecthUnAssignedAttributes();
      fetchAssignedAttributes();
    }
    GetCategories();
    GetGroup();
    GetBrands();
  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>{GroupID == "0" ? "Add" : "Manage"} Group</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Menu">Groups <AiOutlineRight /></Link></li>
              <li>Manage Group</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{GroupID == "0" ? "Add" : "Update " + name} Group</h3>
                  </div>
                 
                </div>
              </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-12 text-end">
                    <NavLink to="/Groups" className="btn btn-secondary">
                      Cancel
                    </NavLink>
                    <button
                      type="submit"
                      className="btn btn-primary m-1 "
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="col-lg-12 mb-4">
                      <label className="mb-2">
                        Group Name <span className="required">*</span>
                      </label>
                      <input
                        className="form-control required"
                        required
                        id="name "
                        onChange={(e) => setName(e.target.value)}
                        value={name !== "" ? name : ""}
                        placeholder="Group Name..."
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label className="mb-2">Categories</label>
                      <Multiselect
                        options={allCategories}
                        selectedValues={selectedCategories}
                        displayValue="CategoryName"
                        onSelect={(e) => setSelectedCategories(e)}
                        onRemove={(e) => setSelectedCategories(e)}
                        placeholder="Select categories"
                      />
                    </div>
                    <div className="col-lg-12">
                      <label>Brands</label>
                      <Multiselect
                        options={allBrands}
                        selectedValues={selectedBrands}
                        displayValue="Brand1"
                        onSelect={(e) => setSelectedBrands(e)}
                        onRemove={(e) => setSelectedBrands(e)}
                        placeholder="Select brands"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <br />
                {GroupID != 0 && (
                  <>
                    <div className="row">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-6">
                            <h5 className="card-title">Assigned Attribute </h5>
                          </div>
                          <div className="col-6 text-right">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                setisOpenUnAssignedAttributes(true)
                              }
                            >
                              Assign Attribute
                            </button>
                            <Modal
                              dialogClassName="w-max"
                              show={isOpenUnAssignedAttributes}
                              onHide={() =>
                                setisOpenUnAssignedAttributes(false)
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Assign Request</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row mb-3">
                                        <div className="col-8"></div>
                                        <div className="col-4 text-right tblSearchBar position-relative">
                                          <span>
                                            <MdManageSearch />
                                          </span>
                                          <input
                                            type="text"
                                            placeholder="Search any field in table"
                                            className="form-control form-control-sm"
                                            onChange={(e) => handleSearchUnAssignedAttributes(e)}
                                          ></input>
                                        </div>
                                      </div>
                                      <div className="portalTable">
                                        <DataTable
                                          columns={unassignedAttributesColumns}
                                          data={filteredUnassignedAttributes}
                                          pagination
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12 text-right">
                                      <Button
                                        variant="secondary"
                                        onClick={() =>
                                          setisOpenUnAssignedAttributes(false)
                                        }
                                      >
                                        Close
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col-8"></div>
                          <div className="col-4 text-right tblSearchBar position-relative">
                            <span>
                              <MdManageSearch />
                            </span>
                            <input
                              type="text"
                              placeholder="Search any field in table"
                              className="form-control form-control-sm"
                              onChange={(e) => handleSearchAssignedAttributes(e)}
                            ></input>
                          </div>
                        </div>
                        <div className="portalTable">
                          <DataTable
                            columns={assignedAttributesColumns}
                            data={filteredAssignedAttributes}
                            pagination
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-6">
                            <h5 className="card-title">
                              Assigned Attribute Values
                            </h5>
                          </div>
                          <div className="col-6 text-right">
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                setisOpenUnAssignedAttributeValues(true)
                              }
                            >
                              Assign Attribute Value
                            </button>
                            <Modal
                              dialogClassName="w-max"
                              show={isOpenUnAssignedAttributeValues}
                              onHide={() =>
                                setisOpenUnAssignedAttributeValues(false)
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Assign Request</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row mb-3">
                                        <div className="col-8"></div>
                                        <div className="col-4 text-right tblSearchBar position-relative">
                                          <span>
                                            <MdManageSearch />
                                          </span>
                                          <input
                                            type="text"
                                            placeholder="Search any field in table"
                                            className="form-control form-control-sm"
                                            onChange={(e) => handleSearchUnAssignedAttributeValues(e)}
                                          ></input>
                                        </div>
                                      </div>
                                      <div className="portalTable">
                                        <DataTable
                                          columns={unassignedAttributeValuesColumns}
                                          data={filteredUnassignedAttributeValues}
                                          pagination
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12 text-right">
                                      <Button
                                        variant="secondary"
                                        onClick={() =>
                                          setisOpenUnAssignedAttributeValues(
                                            false
                                          )
                                        }
                                      >
                                        Close
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col-8"></div>
                          <div className="col-4 text-right tblSearchBar position-relative">
                            <span>
                              <MdManageSearch />
                            </span>
                            <input
                              type="text"
                              placeholder="Search any field in table"
                              className="form-control form-control-sm"
                              onChange={(e) => handleSearchAssignedAttributeValues(e)}
                            ></input>
                          </div>
                        </div>
                        <div className="portalTable">
                          <DataTable
                            columns={assignedAttributeValuesColumns}
                            data={filteredAssignedAttributeValues}
                            pagination
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ManageGroup;
