import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Variables } from "../../../Variations/Variations";
import { BiImage } from "react-icons/bi";
import { PaginatedList } from "react-paginated-list";
function VariationGallery(props) {
  const values = [true, "sm-down", "md-down", "lg-down", "xl-down", "xxl-down"];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [imageDetails, setImageDetails] = useState({});
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
    fetchMedia();
  }
  const [Media, setMedia] = useState([]);
  const [filteredData, setFilteredData] = useState(Media);

  let NUM_OF_RECORDS = Media.length;
  let LIMIT = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const currentData = filteredData.slice(
    (currentPage - 1) * LIMIT,
    (currentPage - 1) * LIMIT + LIMIT
  );

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  function handleImages(e, id, image) {
    var index = 0;
    var updatedList = [...props.allMedia];

    if (e.target.checked) {
      updatedList = [
        ...updatedList,
        { MediaId: id, Image: image, index: props.index },
      ];
    } else {
      // updatedList=updatedList.filter((item) => item.MediaId != id)
      for (var i = 0; i < updatedList.length; i++) {
        if (updatedList[i].MediaId == id) {
          updatedList.splice(i, 1);
        }
      }
    }
    props.setAllMedia(updatedList);
  }

  const handleDrop = async (files) => {
    debugger;
    var count = 0;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      if (files[i].size >= 2000000) {
        count = count + 1;
      } else {
        reader.onload = async function () {
          const formData = new FormData();
          formData.append("Image", files[i]);
          try {
            const res = await axios.post(
              Variables.API_URL + "UploadMedia/UploadImageToServer",
              formData
            );
            if (res.data !== "") {
              await fetch(Variables.API_URL + "UploadMedia/InsertMedia", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  image: res.data,
                  imageTitle: files[i].name.split(".").slice(0, -1).join("."),
                  altText: "",
                  description: "",
                  caption: "",
                }),
              }).then((Response) => {
                if (Response.ok) {
                  fetchMedia();
                  toast.success("Uploaded Successfully");
                } else {
                  toast.error(Response);
                }
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        };
        reader.readAsDataURL(file);
      }
    }

    if (count === 1) {
      var error1 = " Image is not accepted ";
      toast.error(error1);
    } else if (count > 1) {
      var error2 = count + " Images are not accepted ";
      toast.error(error2);
    }
  };

  function fetchMedia() {
    fetch(Variables.API_URL + "UploadMedia/GetMedia", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setMedia(Result);
        setFilteredData(Result);
      });
  }

  function handleChoose() {
    var array = [...props.variations];
    var list = [...props.allMedia];
    var updatedList = [];
    for (var k = 0; k < list.length; k++) {
      if (list[k].index == props.index) {
        updatedList.push(list[k]);
      }
    }
    if (updatedList.length > 5) {
      toast.error("Please choose 5 pictures only");
    } else {
      for (var j = 0; j < array.length; j++) {
        if (array[j].index == props.index) {
          for (var i = 0; i < 5; i++) {
            if (updatedList[i] === undefined) {
              array[j]["ImageId" + i] = 0;
              array[j]["Image" + i] = "";
            } else {
              array[j]["ImageId" + i] = updatedList[i].MediaId;
              array[j]["Image" + i] = updatedList[i].Image;
            }
          }
        }
      }

      props.setVariations(array);
      setShow(false);
    }
  }
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = Media.filter((data) => {
      return data.ImageTitle.search(value) != -1;
    });
    setFilteredData(result);
  };

  function handleShowMore() {
    setFilteredData(Media);
    setShowMore(false);
  }

  function handleShowLess() {
    var array = [];
    for (var i = 0; i < 12; i++) {
      array.push(Media[i]);
    }
    setFilteredData(array);
    setShowMore(true);
  }

  function handleShowImageDetails(image) {
    setShowImageDetails(true);
    setImageDetails(image);
  }
  useEffect(() => {
    fetchMedia();
  }, []);

  return (
    <div className="col-12">
      <Button
        className="me-2 btn btn-secondary btn-sm"
        onClick={() => handleShow(true)}
        style={{
          height: "50px",
          marginTop: "20px",
          width: "200px",
          marginBottom: "20px",
        }}
      >
        <BiImage />{" "}
      </Button>

      <Modal show={show}  onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add images to variation gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="Library"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Upload" title="Upload File">
              <div className="row mt-5">
                <div className="row">
                  <Dropzone
                    onDrop={handleDrop}
                    accept="image/jpeg,image/png,image/jpg,image/svg"
                    style={{ height: 500 }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p style={{ height: 500 }}>
                          Drag'n'drop images, or click to select files
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <br />
                </div>
              </div>
            </Tab>
            <Tab eventKey="Library" title="Media Library">
              <div className="col-12">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-3">
                      <input
                        type="search"
                        className="form-control"
                        onChange={(e) => handleSearch(e)}
                        placeholder={"Search media by name"}
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                    <PaginatedList
                      list={filteredData}
                      itemsPerPage={18}
                      displayRange={2}
                      showPrev={false}
                      showNext={false}
                      renderList={(list) => (
                        <>
                            <div className="row">
                          {list.map((image, id) => {
                            return (
                              <div className="col-2" key={image.MediaId}>
                              <div className="imagecontainer">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={image.MediaId}
                                    id={image.MediaId}
                                    onChange={(e) =>
                                      handleImages(e, image.MediaId, image.Image)
                                    }
                                    checked={
                                      props.allMedia.filter(
                                        (value) =>
                                          value.MediaId == image.MediaId &&
                                          value.index == props.index
                                      ).length !== 0
                                        ? props.allMedia.filter(
                                            (value) =>
                                              value.MediaId == image.MediaId &&
                                              value.index == props.index
                                          )[0].MediaId
                                        : false
                                    }
                                  />
                                  <button
                                    className="btn "
                                    onClick={() => handleShowImageDetails(image)}
                                  >
                                    <label className="form-check-label">
                                      <img
                                        alt="media"
                                        className="form-control"
                                        src={Variables.API_PUBLISHED + image.Image}
                                      />
                                    </label>
                                  </button>
                                </div>
                              </div>
                            </div>
                            );
                          })}
                          </div>
                        </>
                      )}
                    />
                  </div>
              </div>
         
              <div className="row">
                <div className="card-footer text-right">
                  <button className="btn btn-primary" onClick={handleChoose}>
                    Choose
                  </button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>

      {showImageDetails === true ? (
        <Modal
          show={showImageDetails}
          fullscreen={false}
          onHide={() => setShowImageDetails(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Image Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <label style={{ fontWeight: "bolder" }}>
                    {" "}
                    Image Title :{" "}
                  </label>{" "}
                  {imageDetails.ImageTitle}
                </div>
                <div className="col-12">
                  <label style={{ fontWeight: "bolder" }}> Image Path : </label>{" "}
                  {imageDetails.Image}
                </div>
                <div className="col-12">
                  <label style={{ fontWeight: "bolder" }}>
                    {" "}
                    Uploaded Date :{" "}
                  </label>{" "}
                  {imageDetails.UploadedDate}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </div>
  );
}

export default VariationGallery;
