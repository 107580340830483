import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import PagesMediaModal from "./PagesMediaModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Util from "../../Classes/Util";
import { AiOutlineRight } from 'react-icons/ai'

const ManagePages = () => {

  const [title, setTitle] = useState("");
  const [pageDetail, setPageDetail] = useState("");
  const [permalink, setPermalink] = useState("");
  const [picture, setPicture] = useState(0);
  const [image, setImage] = useState("")
  const [publishedDate, setPublishedDate] = useState(new Date(1800,1,1))
  const [publish, setPublish] = useState(false)
  let navigate = useNavigate()
  let { PageID } = useParams()
  const [metaTitle, setMetaTitle] = useState("")
  const [keyWords, setKeyWords] = useState("")
  const [metaDetails, setMetaDetails] = useState("")
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));

  function handleCancel() {
    navigate("/Pages")
  }

  function IsValid() {
    if (title === "") {
      toast.error("Please A Choose A Title For The Page")
      return false
    }
    return true
  }
  async function GetPage() {
    const URL = Variables.API_URL + "Pages/getPage/" + PageID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setTitle(listItems.Title)
    setPageDetail(listItems.Html)
    setPublishedDate(listItems.DateOfPublishing)
    setPermalink(listItems.Url)
    setImage(listItems.Image)
    setPicture(listItems.Picture)
    setMetaDetails(listItems.MetaDetails)
    setMetaTitle(listItems.MetaTitle)
    setKeyWords(listItems.MetaKeyWords)
  }

  function handleSubmit(e) {
    e.preventDefault()
    console.log( JSON.stringify({
      Title: title,
      Html: pageDetail,
      Publish: publish,
      DateOfPublishing: publishedDate,
      Url: permalink,
      Image: picture,
      MetaTitle: metaTitle,
      MetaDetails: metaDetails,
      MetaKeyWords: keyWords
    }))
    if (IsValid()) {
      if (PageID == 0) {
        const addPage = async () => {
          let response = await fetch(Variables.API_URL + "Pages/AddPage", {
            method: "Post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Title: title,
              Html: pageDetail,
              Publish: publish,
              DateOfPublishing: publishedDate,
              Url: permalink,
              Image: picture,
              MetaTitle: metaTitle,
              MetaDetails: metaDetails,
              MetaKeyWords: keyWords
            }),
          });
          let result = await response.json();
          if (response.status === 200) {
            toast.success(result);
            setTimeout(() => {

              navigate("/Pages")
            }, 600);
          }
          else {
            toast.error(result);
          }
        }
        setTimeout(() => {
          (async () => await addPage())();
        }, 100);

      }
      else {
        const URL = Variables.API_URL + "Pages/UpdatePage/" + PageID;
        fetch(URL, {
          method: "Put",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Title: title,
            Html: pageDetail,
            Publish: publish,
            DateOfPublishing: publishedDate,
            Url: permalink,
            Image: picture,
            MetaTitle: metaTitle,
            MetaDetails: metaDetails,
            MetaKeyWords: keyWords

          }),
        }).then((response) => {
          if (response.ok) {
            toast.success("Updated Successfully")
            navigate("/Pages");
          } else {
            toast.error(response);
          }
        });
      }
    }
  }

  function handlePublishingDate(e) {
    if (e.target.value !== "") {
      setPublishedDate(e.target.value)
      setPublish(true)
    }
    else {
      setPublishedDate("")
      setPublish(false)
    }
  }
  function handleKeyWords(e) {

    if (e != "") {
      setKeyWords((e.target.value).replace(/ /g, ","));
    }

  }


  useEffect(() => {

    if (PageID != 0) {
      GetPage()
    }

  }, [PageID]);

  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>Manage Page: {title}</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li><Link to="/Pages">Pages <AiOutlineRight /></Link></li>
              <li>Manage Page</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="row">
                <div className="col-12 text-end">
                  <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                  <button className="btn btn-primary ms-2" onClick={handleSubmit}>Save</button>
                </div>
              </div>
              <div className="card-body">

                <div className="row">
                  <div className="col-7">
                    <div className="col-12 mb-5">
                      <label className="mb-3"> Title</label>

                      <input
                        type="text"
                        placeholder="Add Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control title"
                      />
                    </div>

                    <div className="col-12">
                      <label className="mb-3">
                        Page Details
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={pageDetail}
                        value={pageDetail}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPageDetail(data);
                        }}

                      />
                    </div>
                  </div>
                  <div className="col-5">
                    {permissions.PublishPage === true ? (
                      <div className="row">
                        <div className="col-3">
                          <label> Publish </label>
                        </div>
                        <div className="col-9">
                          <input type="datetime-local" className="form-control" value={publishedDate} onChange={(e) => handlePublishingDate(e)} />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-12">
                      <Accordion className="accordion" defaultActiveKey={['0', '1', '2']} alwaysOpen >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>PermaLink</Accordion.Header>
                          <Accordion.Body>
                            <label>URL Slug</label>
                            <input
                              className="form-control"
                              type="text"
                              value={permalink}
                              onChange={(e) => setPermalink(e.target.value)}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Featured Image</Accordion.Header>
                          <Accordion.Body>

                            <PagesMediaModal setPicture={setPicture} setImage={setImage} />
                            {image !== "" ? (
                              <img src={Variables.API_PUBLISHED + image} className="form-control" />
                            ) : null}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Meta</Accordion.Header>
                          <Accordion.Body>
                            <div className="col-12">
                              <div className="row">
                                <label>
                                  Title
                                </label>
                                <input type="text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} className="form-control" />
                              </div>
                              <div className="row">
                                <label>
                                  Key Words
                                </label>
                                <input type="text" value={keyWords} onChange={handleKeyWords} className="form-control" />
                              </div>
                              <div className="row">
                                <label>
                                  Details
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={metaDetails}
                                  value={metaDetails}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setMetaDetails(data);
                                  }}

                                />
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
        />
      </div>
    </div>
  );
};

export default ManagePages;
