import React, { useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Variables } from "../../Variations/Variations";
import Util from "../../Classes/Util";
import { Modal, Button } from "react-bootstrap";
import Pagination from "../Products/Pagination/Pagination";
import { toast } from "react-toastify";
import { PaginatedList } from "react-paginated-list";
import { AiOutlineRight } from "react-icons/ai";
import { MdManageSearch } from "react-icons/md";
const ListMedia = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [showImageDetails, setShowImageDetails] = useState(false);
  const [imageDetails, setImageDetails] = useState("");
  const [Media, setMedia] = useState([]);
  const [filteredData, setFilteredData] = useState(Media);
  const [show, setShow] = useState(0);
  let NUM_OF_RECORDS = filteredData.length;

  let LIMIT = 18;
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    if (value === "") {
      result = Media;
    } else {
      result = Media.filter((data) => {
        return data.ImageTitle.search(value) != -1;
      });
    }

    setFilteredData(result);
  };

  function handleImageDetails(image) {
    setImageDetails(image);
    setShowImageDetails(true);
  }

  function fetchMedia() {
    fetch(Variables.API_URL + "UploadMedia/GetMedia", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((Response) => Response.json())
      .then((Result) => {
        setMedia(Result);
        setFilteredData(Result);
      });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const currentData = filteredData.slice(
    (currentPage - 1) * LIMIT,
    (currentPage - 1) * LIMIT + LIMIT
  );

  const onPageChanged = useCallback(
    (event, page) => {
      event.preventDefault();
      setCurrentPage(page);
    },
    [setCurrentPage]
  );
  const DeleteMedia = async () => {
    let response = await fetch(
      Variables.API_URL + "UploadMedia/DeleteMedia/" + imageDetails.MediaId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      setShowImageDetails(false);
      setImageDetails("");
      fetchMedia();
    } else {
      toast.error("Please Check With Administrator");
    }
  };
  useEffect(() => {
    fetchMedia();
  }, []);
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>Media Library</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>Media Library</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="col-12">
                  <div className="row mb-3">
                    <div className="col-4 text-end tblSearchBar position-relative">
                      <span>
                        <MdManageSearch />
                      </span>
                      <input
                        type="text"
                        placeholder="Search by name"
                        className="form-control form-control-sm"
                        onChange={(e) => handleSearch(e)}
                      ></input>
                    </div>
                    <div className="col-8 text-end">
                    {permissions.UploadMedia === true ? (
                    <NavLink to="/Media/0" className="btn btn-primary">
                      Add New
                    </NavLink>
                  ) : null}
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-12">
                  <div className="row">
                    <PaginatedList
                      list={filteredData}
                      itemsPerPage={18}
                      displayRange={2}
                      showPrev={false}
                      showNext={false}
                      renderList={(list) => (
                        <>
                          <div className="row">
                            {list.map((image, id) => {
                              return (
                                <div className="col-2" key={image.MediaId}>
                                  <div className="imagecontainer">
                                    <div className="form-check">
                                      <button
                                        className="btn "
                                        onClick={() =>
                                          handleImageDetails(image)
                                        }
                                      >
                                        <label className="form-check-label">
                                          <img
                                            alt="media"
                                            className="form-control"
                                            src={
                                              Variables.API_PUBLISHED +
                                              image.Image
                                            }
                                          />
                                        </label>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>

              </div>
              {showImageDetails === true ? (
                <Modal
                  show={showImageDetails}
                  fullscreen={false}
                  onHide={() => setShowImageDetails(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Image Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          <label style={{ fontWeight: "bolder" }}>
                            {" "}
                            Image Title :{" "}
                          </label>{" "}
                          {imageDetails.ImageTitle}
                        </div>
                        <div className="col-12">
                          <label style={{ fontWeight: "bolder" }}>
                            {" "}
                            Image Path :{" "}
                          </label>{" "}
                          {imageDetails.Image}
                        </div>
                        <div className="col-12">
                          <label style={{ fontWeight: "bolder" }}>
                            {" "}
                            Uploaded Date :{" "}
                          </label>{" "}
                          {imageDetails.UploadedDate}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="container">
                      <div className="row">
                        <div className="col-10"></div>
                        <div className="col-1">
                          <button
                            className="btn btn-primary"
                            onClick={() => setShow(imageDetails.MediaId)}
                          >
                            {" "}
                            Delete{" "}
                          </button>
                        </div>
                        <div className="col-1">
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              setImageDetails("");
                              setShowImageDetails(false);
                            }}
                          >
                            {" "}
                            Cancel{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal.Footer>
                </Modal>
              ) : null}
              <Modal
                show={imageDetails.MediaId === show}
                onHide={() => setShow(0)}
              >
                <Modal.Header>
                  <Modal.Title>Delete Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <p>
                        Are you sure you want to
                        <b> Delete</b> the media named{" "}
                        <b>{imageDetails.ImageTitle}</b>?
                      </p>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="container">
                    <div className="row">
                      <div className="col-8"></div>

                      {permissions.DeleteMedia && (<div className="col-2">
                        <Button
                          variant="primary"
                          className="form-control"
                          onClick={() => DeleteMedia()}
                        >
                          Yes
                        </Button>
                      </div>)}
                      <div className="col-2">
                        <Button
                          variant="secondary"
                          className="form-control"
                          onClick={() => setShow(0)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListMedia;
