import React, { useEffect, useState } from "react";
import { Variables } from "../../../Variations/Variations";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiTrash } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import ProductManageAttributeValues from "./ProductManageAttributeValues";

const ProductAttributes = (props) => {
  //#region variables
  const navigate = useNavigate();
  const multiselectRef = React.createRef();
  const [show, setShow] = useState(false);
  const [AttributeId, setAttributeId] = useState("");
  //#endregion
  //#region functions

  // save attributes
  const handleSaveAttributes = () => {
    props.setSavedAssigned(props.productSelectedAttributes);
    toast.success("Saved Successfully");
  };

  // handle Attibute Value Change yyyyyyyy
  const handleAttributeValueChangeChange = (e, i) => {
    try {
      const attributeToUpdate = props.productSelectedAttributes[i];
      const array = [...props.productSelectedAttributes];
      delete array[i];
      attributeToUpdate.AttributeValues = e;
      array[i] = attributeToUpdate;
      props.setAssignedAttributes(array);
    } catch (error) {
      toast.error("Error", error);
    }
  };

  //Delete Attribute
  const DeleteAttribute = (i) => {
    const array = [...props.productSelectedAttributes];
    // var c = array.indexOf()
    array.splice(i, 1);
    props.setProductSelectedAttributes(array);
  };

  //handle add product new attribute
  function handleAddAttribute() {
    fetch(
      Variables.API_URL +
      "Attributes/GetAttributeDetailsByAttributeID/" +
      props.ddlSelectedAttribute,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((Response) => Response.json())
      .then((Result) => {
        if (Result != null) {
          let array = [...props.productSelectedAttributes];
          // let array=[...newAttribute];
          let newObj = {
            AttributeId: Result[0].AttributeId,
            AttributeName: Result[0].AttributeName,
            AttributeValues: [],
            AllAttributeValues: Result[0].AllAttributeValues,
          };
          array.push(newObj);
          props.setProductSelectedAttributes(array);
        }
      })
      .catch(function (error) {
        toast.error(error);
      });
    props.setAttributesList(
      props.attributesList.filter(
        (item) => item.AttributeId != props.ddlSelectedAttribute
      )
    );
    props.setDdlSelectedAttribute(0);
  }

  //Handle select dropdown seletect attribute
  const handleSelectedAttribute = (e) => {
    const { name, value } = e.target;
    props.setDdlSelectedAttribute(value);
  };

  // assign all attribute values
  function AssignAllAttributesValues(id) {
    let array = [...props.productSelectedAttributes];
    for (var i = 0; i < array.length; i++) {
      if (array[i].AttributeId == id) {
        let newObj = {
          AttributeId: array[i].AttributeId,
          AttributeName: array[i].AttributeName,
          AttributeValues: array[i].AllAttributeValues,
          AllAttributeValues: array[i].AllAttributeValues,
        };
        array[i] = newObj;
      }
    }
    props.setProductSelectedAttributes(array);
  }

  // unassign all attribute values
  function UnAssignAllAttributesValues(id) {
    multiselectRef.current.resetSelectedValues();
    let array = [...props.productSelectedAttributes];
    for (var i = 0; i < array.length; i++) {
      if (array[i].AttributeId == id) {
        let newObj = {
          AttributeId: array[i].AttributeId,
          AttributeName: array[i].AttributeName,
          AttributeValues: [],
          AllAttributeValues: array[i].AllAttributeValues,
        };
        array[i] = newObj;
      }
    }
    props.setProductSelectedAttributes(array);
  }

  const GetAssignedProductAttributesValues = () => {
    const URL =
      Variables.API_URL +
      "Attributes/GetAssignedProductAttributesValues/" +
      props.ProductID;
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result !== "0") {
          props.setProductSelectedAttributes(result);
          props.setSavedAssigned(result);
          GetAttributesList()
        }
      });
  };

  // Get Attribute By Id
  async function GetAttributesByID(id) {
    const URL = Variables.API_URL + "Attributes/GetAttributeById/" + id;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    props.setSelectedAttributes([
      ...props.selectedAttributes,
      {
        AttributeId: id,
        Attribute1: listItems[0].Attribute1,
        AttributeTypeId: listItems[0].AttributeTypeId,
        AttributeValues: listItems[0].AttributeValues,
      },
    ]);
  }

  //Get Attributes List
  const GetAttributesList = () => {
    const URL = Variables.API_URL + "Attributes/GetAttributeDetails";
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result === "0") {
          // error message
        } else {
          for (var i = 0; i < result.length; i++) {
            if (props.savedAssigned[i] !== undefined) {
              result = result.filter(
                (item) => item.AttributeId != props.savedAssigned[i].AttributeId
              );
            }
          }
        }
        props.setAttributesList(result);
      }

      );
  };

  // Get product selected Attributes
  async function GetSelectedAttributes() {
    const URL =
      Variables.API_URL + "Attributes/GetAttributeById1/" + props.ProductID;
    const response = await fetch(URL);
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    props.setSelectedAttributes(listItems);
    //setProductSelectedAttributes(listItems);
    for (var i = 0; i < listItems.Length; i++) {
      GetAttributesByID(listItems[i].AttributeId);
    }
  }

  //Get products attributes Values
  const GetProductsAttributesValues = () => {
    const URL =
      Variables.API_URL +
      "Attributes/GetAllAttributesValues/" +
      props.ProductID;
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        if (result !== "0") {
          props.setProductAttributesValues(result);
        }
      });
  };

  useEffect(() => {
    GetSelectedAttributes();
    GetAttributesList();
    if (props.ProductID != 0) {
      GetProductsAttributesValues();
      GetAssignedProductAttributesValues();
    }
  }, []);
  //#endregion

  return (
    <div className="row linked item">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-10">
            <select
              className="form-select"
              required
              value={props.ddlSelectedAttribute}
              onChange={handleSelectedAttribute}
            >
              <option value="0">-- Select --</option>
              {props.attributesList.map((c) => {
                return (
                  <option value={c.AttributeId} key={c.AttributeId}>
                    {c.AttributeName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-2">
            <button className="btn btn-primary" onClick={handleAddAttribute}>
              +
            </button>
          </div>
        </div>
      </div>

      <div className="col-lg-12 p-3">
        {props.productSelectedAttributes.map((data, i) => (
          <div className="row p-3" key={data.AttributeId}>
            <div className="col-12">
              <div className="row">
                <div className="col-12 mb-3">
                  <label>
                    {" "}
                    Name: &nbsp;<b>{data.AttributeName} </b>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <Multiselect
                    ref={multiselectRef}
                    options={data.AllAttributeValues}
                    selectedValues={data.AttributeValues}
                    displayValue="AttributeValueName"
                    onSelect={(e) =>
                      handleAttributeValueChangeChange(e, i)
                    }
                    onRemove={(e) =>
                      handleAttributeValueChangeChange(e, i)
                    }
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-3 ">
                  <button
                    className="btn btn-light"
                    onClick={() =>
                      AssignAllAttributesValues(data.AttributeId)
                    }
                  >
                    {" "}
                    Select All
                  </button>
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-light"
                    onClick={() =>
                      UnAssignAllAttributesValues(data.AttributeId)
                    }
                  >
                    {" "}
                    None
                  </button>
                </div>

                <div className="col-3">
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      setShow(true);
                      setAttributeId(data.AttributeId);
                    }}
                  >
                    Add New
                  </button>
                </div>

                <Modal
                  show={show}
                  fullscreen={true}
                  onHide={() => setShow(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add Attribute Value</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <ProductManageAttributeValues
                      AttributeId={AttributeId}
                    />
                  </Modal.Body>
                </Modal>

                <div className="col-3">
                  <button
                    className="btn btn-primary"
                    style={{ marginTop: "5px" }}
                    onClick={() => DeleteAttribute(i)}
                  >
                    <BiTrash />
                  </button>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>

      {props.productSelectedAttributes.length != 0 ? (
        <div className="row">
          <div className="col-3">
            <button className="btn btn-primary" onClick={handleSaveAttributes}>
              {" "}
              Save Attributes{" "}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductAttributes;
