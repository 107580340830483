import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom"
import DataTable from 'react-data-table-component';
import { Variables } from "../../Variations/Variations"
import { BiTrash, BiEdit, BiShowAlt, BiHide } from 'react-icons/bi'
import { ToastContainer, toast } from 'react-toastify';
import Util from "../../Classes/Util";
import { AiOutlineClose, AiOutlineCheck, AiOutlineRight } from 'react-icons/ai';
import { Button, Modal } from "react-bootstrap";

const Contents = () => {
  const [listcontents, setcontents] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  const [show, setShow] = useState(0)

  async function TooglePublishContent(id) {
    let response = await fetch(
      Variables.API_URL + "Content/TooglePublishContent/" + id,
      {
        method: "Put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      Getcontents();
    } else {
      toast.error(result);
    }
  }


  const columns = [
    {
      name: 'ID',
      selector: row => row.ContentId,
      sortable: true,
    },
    {
      name: 'Content Title',
      selector: row => row.Name,
      sortable: true,
    },
    {
      name: 'Page',
      selector: row => row.Page,
      sortable: true,
    },
    {
      name: 'Published',
      selector: row => row.IsPublished ? <AiOutlineCheck /> : <AiOutlineClose />,
      sortable: true,
    },
    {
      name: 'Options',
      selector: row => <div>
        {permissions.PublishContent && (<button className="btn btn-outline-secondary btn-sm" onClick={() => TooglePublishContent(row.ContentId)}>{row.IsPublished ? <BiHide /> : <BiShowAlt />} </button>)}
        {permissions.ManageContent && (<NavLink to={"/Contents/" + row.ContentId} className="btn btn-outline-warning btn-sm ms-2" ><BiEdit /></NavLink>)}
        {permissions.DeleteContent && (<button className="btn btn-outline-danger btn-sm ms-2" onClick={() => setShow(row.ContentId)}><BiTrash /></button>)}
        <Modal
          show={row.ContentId === show}
          onHide={() => setShow(0)}
        >
          <Modal.Header>
            <Modal.Title>Delete Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <p>
                  Are you sure you want to
                  <b> Delete</b> the Content named <b>{row.Name}</b>?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-8"></div>
                <div className="col-2">
                  <Button
                    variant="primary"
                    className="form-control"
                    onClick={() => DeleteContent(row.ContentId)}
                  >
                    Yes
                  </Button>
                </div>
                <div className="col-2">
                  <Button
                    variant="secondary"
                    className="form-control"
                    onClick={() => setShow(0)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>,
    }
  ];

  async function Getcontents() {
    const URL = Variables.API_URL + "Content/GetContents";
    const response = await fetch(URL)
    if (!response.ok) throw Error("Did Not Receive expected data");
    const listItems = await response.json();
    setcontents(listItems);
  }
  const DeleteContent = async (ContentId) => {
    let response = await fetch(
      Variables.API_URL + "Content/DeleteContent/" + ContentId,
      {
        method: "Delete",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": Variables.API_URL,
        },
      }
    );
    let result = await response.json();
    if (response.ok) {
      toast.success(result);
      Getcontents();
    } else {
      toast.error("Please Check With Administrator");
    }
  };



  //#region Use Effect 
  useEffect(() => {
    Getcontents();
  }, [])
  //#endregion Use Effect 
  return (
    <div className="rightSide">
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h2>List contents</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>List contents</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header">
                <div className="row">
                  <div className="col-8">
                    <h3>List contents</h3>
                  </div>
                  {permissions.ManageContent && (<div className="col-4 text-right">
                    <NavLink to='/Contents/0' className="btn btn-primary">New Content</NavLink>
                  </div>)}
                </div> */}

            {/* </div> */}
            <div className="card-body">
              {permissions.ManageContent && (<div className="text-right mb-3">
                <NavLink to='/Contents/0' className="btn btn-primary">New Content</NavLink>
              </div>)}
              <div className="portalTable">
                <DataTable
                  columns={columns}
                  data={listcontents}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick />
    </div>
    </div >
  )
}
export default Contents;